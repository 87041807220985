import { useCallback, useEffect, useState } from "react";
import Image from 'next/image';
import axios from "axios";
import _ from 'lodash';
import { MinProductItemProps, SimpleProductItemProps } from "../Product/constants";
import Link from "next/link";
import { get_original_price, get_sales_price } from "@/lib/Definitions";
import { useSuggestSearchStore } from "@/stores/useSuggestSearchStore";
import { useRouter } from "next/router";


type SearchResult = {
  keywords: Array<string>;
  products: Array<MinProductItemProps>;
  product_list: Array<SimpleProductItemProps>;
}

interface SearchProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const Search = (props: SearchProps) => {
  const router = useRouter();
  const { isVisible, setIsVisible } = props;
  const { suggestSearch, getSuggestSearch } = useSuggestSearchStore();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);
  const [isSearched, setIsSearched] = useState(false);
  const [suggestSearchData, setSuggestSearchData] = useState(getSuggestSearch());
  const specialKeywords = ["m2", "m3"];

  useEffect(() => {
    setSuggestSearchData(getSuggestSearch());
  }, [getSuggestSearch, suggestSearch])

  useEffect(() => {
    setIsOpen(isVisible);
  }, [setIsOpen, isVisible])

  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.removeProperty('overflow'));
  }, [isOpen])

  const handleClick = (link: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    setIsOpen(!isOpen);
    setIsVisible(!isVisible);
    setIsSearched(false);
    setSearchTerm('');
    setSearchResult(null);

    router.push(link);
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchTerm(value);
    delayedSearch(value);
  }

  const keydownSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchTerm.length > 0) {
      router.push("/product-category/all-category?keyword=" + searchTerm);
    }
  }

  const show_sales_price = (product: MinProductItemProps) => {
    const sales_price = get_sales_price(product, null);

    if (Array.isArray(sales_price.price)) {
      return (
        <p className={`flex font-semibold text-price text-xs`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[0].toLocaleString()} ~
        </p>
      )
    } else {
      return (
        <p className={`flex font-semibold text-xs ${sales_price.promotion ? "text-mooimom" : "text-price"}`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price.toLocaleString()}
        </p>
      )
    }
  }

  const show_origin_price = (product: MinProductItemProps) => {
    const origin_price = get_original_price(product, null);

    if (origin_price === null) {
      return (<></>)
    } else {
      return (
        <p className="font-medium text-[10px] line-through text-originPrice">
          {process.env.NEXT_PUBLIC_CURRENCY}{origin_price.toLocaleString()}
        </p>
      )
    }
  }

  const delayedSearch = useCallback(
    _.debounce(async (value: string) => {
      if (value.trim().length > 0) {
        try {
          const response = await axios.get('/api/keyword_search', {
            params: { search: value },
          });

          if (response.data.status === "ok") {
            delete response.data.status;
            setSearchResult(response.data);
          } else {
            setSearchResult(null);
          }

          setIsSearched(true);
        } catch (error) {
          setSearchResult(null);
          setIsSearched(true);
        }
      } else {
        setSearchResult(null);
        setIsSearched(true);
      }
    }, 1000),
    []
  );

  return (
    <>
      <div
        className={`${isOpen ? "fixed left-0 w-screen h-screen top-0 right-0 bottom-0 bg-black opacity-30 z-[54]" : ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
          setIsVisible(!isVisible);
          setIsSearched(false);
          setSearchTerm('');
          setSearchResult(null);
        }}
      ></div>
      <div
        className={`fixed w-full md:w-[468px] z-[55] bg-white font-poppins top-0 h-dscreen transition-transform ease-in-out duration-300 transform inset-y-0 -right-full md:-right-[468px] py-12 md:py-8 px-6 ${isOpen ? '-translate-x-full' : 'translate-x-0'}`}
      >
        <div className="flex items-center justify-between space-x-4 md:hidden">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/search.png"}
            alt="search icon"
            width={18}
            height={18}
            quality={100}
            onClick={() => {
              setIsSearched(false);
              setSearchTerm('');
              setSearchResult(null);
            }}
            className="cursor-pointer"
          />
          <input
            type="search"
            className="w-full py-1 text-base text-center text-black border-0 outline-none font-poppins focus:ring-transparent"
            placeholder="Ketik nama produk"
            value={searchTerm}
            onChange={handleSearch}
            onKeyDown={keydownSearch}
            enterKeyHint="search"
          />
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/close.png"}
            alt="close icon"
            width={14}
            height={14}
            quality={100}
            onClick={() => {
              setIsOpen(!isOpen);
              setIsVisible(!isVisible);
              setIsSearched(false);
              setSearchTerm('');
              setSearchResult(null);
            }}
            className="cursor-pointer"
          />
        </div>
        <div className="justify-end hidden md:flex">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/close.png"}
            alt="close icon"
            width={14}
            height={14}
            quality={100}
            onClick={() => {
              setIsOpen(!isOpen);
              setIsVisible(!isVisible);
              setIsSearched(false);
              setSearchTerm('');
              setSearchResult(null);
            }}
            className="cursor-pointer"
          />
        </div>
        <div className="justify-center hidden md:flex">
          <input
            type="search"
            className="w-full py-1 text-base text-center text-black border-0 outline-none font-poppins focus:ring-transparent"
            placeholder="Ketik nama produk"
            value={searchTerm}
            onChange={handleSearch}
            onKeyDown={keydownSearch}
            enterKeyHint="search"
          />
        </div>
        <hr className="mt-2 mb-6 bg-black md:my-8" />
        <div className="w-full pb-4 mb-8 overflow-y-auto search-height">
          {searchResult !== null && (
            <>
              {searchResult.keywords.length > 0 && (
                <>
                  <p className={`text-sm text-black font-semibold`}>Pencarian Terkait</p>
                  <div className="mx-2 mt-4 space-y-4">
                    {searchResult.keywords.map((keyword, index) => (
                      <div key={index}>
                        <Link href={"/product-category/all-category?keyword=" + keyword}>
                          <div className="flex items-center space-x-4">
                            <Image
                              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/search.png"}
                              alt="search icon"
                              width={14}
                              height={14}
                              quality={100}
                            />
                            <p className="text-sm font-medium text-review" dangerouslySetInnerHTML={{ __html: keyword.replace(searchTerm, `<b>${searchTerm}</b>`) }}></p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {searchResult.products.length > 0 && searchResult.products.map((product, index) => (
                <div key={index} className={`p-4 mx-2 ${searchResult.keywords.length === 0 && index === 0 ? "mt-2" : "mt-4"} rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)]`}>
                  <Link href={"/product/" + product.slug} onClick={handleClick("/product/" + product.slug)}>
                    <div className={`flex items-center space-x-[10px]`}>
                      <Image
                        src={product.image}
                        alt={product.name + " image"}
                        width={60}
                        height={60}
                        quality={100}
                        className="rounded aspect-square"
                      />
                      <div className="">
                        <p className="text-xs font-medium text-black capitalize font-poppins line-clamp-2 text-ellipsis">{product.name}</p>
                        <div className="flex items-center space-x-1 mt-[10px]">
                          {show_sales_price(product)}
                          {show_origin_price(product)}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
              {searchResult.product_list.length > 0 && (
                <>
                  <p className={`text-sm text-black font-semibold mt-6`}>Produk Terkait</p>
                  {searchResult.product_list.map((product, index) => (
                    <div key={index} className={`p-4 mx-2 mt-4 rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)]`}>
                      <Link href={"/product/" + product.slug} onClick={handleClick("/product/" + product.slug)}>
                        <div className={`flex items-center space-x-[10px]`}>
                          <Image
                            src={product.image}
                            alt={product.name + " image"}
                            width={60}
                            height={60}
                            quality={100}
                            className="rounded aspect-square"
                          />
                          <div className="">
                            <p className="text-xs font-medium text-black capitalize font-poppins line-clamp-2 text-ellipsis">{product.name}</p>
                            {/* <div className="flex items-center space-x-1 mt-[10px]">
                              {show_sales_price(product)}
                              {show_origin_price(product)}
                            </div> */}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </>
              )}
              {/* {(searchResult.keywords.length === 0 && searchResult.products.length === 0 && searchResult.product_list.length === 0) && (
                <>
                  <p className="text-xs italic text-black">Apakah maksud anda <span className="font-bold">&apos;{searchTerm}&apos;</span></p>
                  <div className="flex items-center mt-4 space-x-4">
                    <Image
                      src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/sad-light.png"}
                      alt="sad icon"
                      quality={100}
                      width={32}
                      height={32}
                    />
                    <p className="text-base font-medium text-black">Oh Tidak, Produk Tidak Ditemukan!</p>
                  </div>
                  <p className="mt-2 text-xs leading-6 text-attributeBorder">Periksa Kembali Kata Kunci - Pastikan ejaan benar atau coba kata kunci yang lebih umum</p>
                </>
              )} */}
            </>
          )}
          {(searchResult === null || searchResult.keywords.length === 0 || (searchResult !== null && searchResult.keywords.length === 0 && searchResult.products.length === 0 && searchResult.product_list.length === 0)) && suggestSearchData.data.keywords.length > 0 && (
            <>
              <p className={`text-sm text-black font-semibold ${searchResult !== null && searchResult.products.length > 0 ? "mt-6 md:mt-8" : ""}`}>Pencarian Terpopuler</p>
              <div className="flex flex-wrap items-center mx-2 mt-4">
                {suggestSearchData.data.keywords.map((keyword, index) => (
                  <div
                    key={index}
                    className="flex items-center p-2 mb-4 mr-4 space-x-1 border rounded-lg cursor-pointer border-reviewBg flex-nowrap min-w-fit"
                    onClick={() => router.push("/product-category/all-category?keyword=" + keyword)}
                  >
                    <Image
                      src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/hot.png"}
                      alt="hot icon"
                      width={16}
                      height={16}
                      quality={100}
                    />
                    <p className="text-sm font-medium text-review whitespace-nowrap">{keyword}</p>
                  </div>
                ))}
              </div>
            </>
          )}
          {(searchResult === null || searchResult.product_list.length === 0) && suggestSearchData.data.products.length > 0 && (
            <>
              <p className={`text-sm text-black font-semibold ${suggestSearchData.data.keywords.length > 0 ? "mt-6 md:mt-8" : ""}`}>Produk Terpopuler</p>
              {suggestSearchData.data.products.map((product, index) => (
                <div key={index} className="p-4 mx-2 mt-4 rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)]">
                  <Link href={"/product/" + product.slug} onClick={handleClick("/product/" + product.slug)}>
                    <div className={`flex items-center space-x-[10px]`}>
                      <Image
                        src={product.image}
                        alt={product.name + " image"}
                        width={60}
                        height={60}
                        quality={100}
                        className="rounded aspect-square"
                      />
                      <div className="">
                        <p className="text-xs font-medium text-black capitalize font-poppins line-clamp-2 text-ellipsis">{product.name}</p>
                        <div className="flex items-center space-x-1 mt-[10px]">
                          {show_sales_price(product)}
                          {show_origin_price(product)}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Search
