import Image from 'next/image';
import { AddToCartProductItemProps, ProductVariantItemProps } from './constants';
import Attribute from './Attribute';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useUserStore } from '@/stores/useUserStore';
import { useCartsStore } from '@/stores/useCartsStore';
import { event } from 'nextjs-google-analytics';
import { getNewToken, get_original_price, get_sales_price } from '@/lib/Definitions';
import * as Dialog from '@radix-ui/react-dialog';
import { useAttributesStore } from '@/stores/useAttributesStore';
import AlertDialog from '../Utils/AlertDialog';
import { initUser } from '@/stores/initialState';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';


const AddToCart = (props: AddToCartProductItemProps) => {
  const router = useRouter();
  const { product_code, name, image, setShowCart, action, open, setOpen } = props;
  const { userInfo, getUser, updateUser } = useUserStore();
  const { carts, getCarts, updateCarts } = useCartsStore();
  const { getAttributes } = useAttributesStore();
  const [qty, setQty] = useState(props.action === "add" ? 1 : props.qty);
  const [variant, setVariant] = useState<ProductVariantItemProps | null>(props.variant);
  const [displayImage, setDisplayImage] = useState(image);
  const [userData, setUserData] = useState(getUser());
  const [cartsData, setCartsData] = useState(getCarts());
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [affiliate, setAffiliate] = useState<string | undefined>(undefined);
  const attributesData = getAttributes();
  const email_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const minus_qty = () => {
    if (variant !== null && variant !== undefined && variant.qty > 0 && qty > 1) {
      setQty(qty - 1);
    }
  }

  const plus_qty = () => {
    if (variant !== null && variant !== undefined && variant.qty > 0 && qty < variant.qty) {
      setQty(qty + 1);
    }
  }

  const add_to_cart = async () => {
    if (variant !== null && variant !== undefined && (variant.qty > 0 || (props.virtual_product && props.selectedCustomVariant !== null && props.selectedCustomVariant.qty > 0))) {
      if (props.virtual_product) {
        if (props.toName === "") {
          setAlertMessage("Silahkan isi nama penerima");
          return;
        } else if (props.toEmail === "") {
          setAlertMessage("Silahkan isi email penerima!");
          return;
        } else if (!email_regex.test(props.toEmail)) {
          setAlertMessage("Email penerima yang anda masukkan salah!");
          return;
        } else if (props.toMobile === "") {
          setAlertMessage("Silahkan isi nomor telepon anda");
          return;
        } else if (props.selectedCustomVariant === null) {
          setAlertMessage("Silahkan pilih " + props.custom_variant_list[0].category + " produk");
          return;
        } else if (props.selectedCustomVariant.qty === 0) {
          setAlertMessage("Stok produk tidak tersedia");
          return;
        } else if (props.selectedCustomVariant.qty < qty) {
          setAlertMessage("Jumlah produk melebihi stok yang tersedia");
          return;
        } else if (props.selectedCustomVariant.pregnancy_status) {
          if (props.pregnancy === "") {
            setAlertMessage("Silahkan isi kondisi anda saat ini, apakah anda sedang hamil?");
            return;
          } else if (props.pregnancy === "pregnant" && props.duDate === null) {
            setAlertMessage("Silahkan isi waktu perkiraan anak anda lahir");
            return;
          }
        } else if (props.selectedCustomVariant.child_status) {
          if (props.childName === "") {
            setAlertMessage("Silahkan isi nama anak anda");
            return;
          } else if (props.childAge === null) {
            setAlertMessage("Silahkan isi usia anak anda");
            return;
          } else if (!/^\+?[1-9]\d*$/.test(props.childAge.toString()) || props.childAge < 0) {
            setAlertMessage("Silahkan isi usia anak anda dengan benar");
            return;
          } else if (props.childGender === "") {
            setAlertMessage("Silahkan pilih jenis kelamin anak anda");
            return;
          }
        }
      }

      const data = {
        product_id: props.id,
        product_variant_id: variant.variant_id,
        qty: qty,
        notify_email: props.toEmail,
        from_name: props.fromName,
        to_name: props.toName,
        phone: props.toMobile,
        custom_variant_id: props.selectedCustomVariant !== null ? props.selectedCustomVariant.id : null,
        pregnancy_status: props.selectedCustomVariant !== null && props.selectedCustomVariant.pregnancy_status ? props.pregnancy : null,
        due_date: props.selectedCustomVariant !== null && props.selectedCustomVariant.pregnancy_status ? props.duDate?.toISOString().slice(0, 7) : null,
        child_name: props.selectedCustomVariant !== null && props.selectedCustomVariant.child_status ? props.childName : null,
        child_age: props.selectedCustomVariant !== null && props.selectedCustomVariant.child_status ? props.childAge : null,
        child_gender: props.selectedCustomVariant !== null && props.selectedCustomVariant.child_status ? props.childGender : null,
        type: affiliate !== null && affiliate !== undefined ? "affiliate" : "ec",
        affiliate: affiliate !== null && affiliate !== undefined ? affiliate : null,
      }

      try {
        const response = await axios.post(
          '/api/cart',
          data,
          { headers: { Authorization: 'Bearer ' + userData.token } }
        )

        event("add_to_cart", {
          category: "ecommerce",
          value: variant.sales_price,
          currency: process.env.NEXT_PUBLIC_CURRENCY_CODE,
          items: [
            {
              item_id: props.product_code,
              item_name: props.name,
              item_variant: variant.product_code,
              brand: props.brand,
              price: variant.special_price !== 0 ? variant.special_price : props.special_price !== 0 ? props.special_price : variant.sales_price,
              quantity: qty,
              image: variant.cart_image
            }
          ]
        });

        try {
          window.fbq('track', 'AddToCart', {
            content_ids: [props.product_code],
            content_name: props.name,
            content_type: 'product',
            value: (variant.special_price !== 0 ? variant.special_price : props.special_price !== 0 ? props.special_price : variant.sales_price) * qty,
            currency: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'IDR',
          })
        } catch (error) { }

        try {
          window.gtag('event', 'AddToCart', {
            items: [
              {
                id: props.product_code,
                name: props.name,
                variant: variant.product_code,
                brand: props.brand,
                quantity: qty,
                price: variant.special_price !== 0 ? variant.special_price : props.special_price !== 0 ? props.special_price : variant.sales_price,
              }
            ]
          })
        } catch (error) { }

        const findIndex = cartsData.data.findIndex(cart => cart.id === response.data.id)

        if (findIndex === -1) {
          cartsData.data.push({
            id: response.data.id,
            product_id: response.data.product_id,
            product_code: props.product_code,
            product_variant_id: response.data.product_variant_id,
            qty: response.data.qty,
            brand: props.brand,
            image: variant.cart_image,
            image_alt: props.name + ' image',
            msrp: props.msrp,
            sales_price: variant.sales_price,
            special_price: variant.special_price !== 0 ? variant.special_price : props.special_price,
            slug: props.slug,
            product_variant_list: props.product_variant_list,
            attribute_value_list: props.attribute_value_list,
            name: props.name,
            checked: true,
            virtual_product: props.virtual_product,
            need_shipping: props.need_shipping,
            custom_variant_list: props.custom_variant_list,
            customVariant: props.selectedCustomVariant,
            fromName: props.fromName,
            toName: props.toName,
            toEmail: props.toEmail,
            toMobile: props.toMobile,
            toMessage: props.toMessage,
            pregnancy: props.pregnancy,
            duDate: props.duDate,
            childName: props.childName,
            childAge: props.childAge,
            childGender: props.childGender,
            pwp_name: props.pwp_name,
            pwp: props.pwp,
            shared_sales_price: props.shared_sales_price,
            type: affiliate !== null && affiliate !== undefined ? "affiliate" : "ec",
          })
        } else {
          cartsData.data[findIndex].qty = response.data.qty;
          cartsData.data[findIndex].product_variant_id = response.data.product_variant_id;
          cartsData.data[findIndex].image = variant.cart_image;
        }

        cartsData.update_date = Date.now();

        updateCarts(cartsData);
        setCartsData(cartsData);
        setShowCart(true);
        setOpen(false);
      } catch (error: any) {
        if (error.response.data.error === "Invalid credentials" && userData.refresh_token !== "") {
          const response: any = await getNewToken(userData.token, userData.refresh_token, userData.uuid, userData.user_id);

          if (response !== "error") {
            userData.token = response.token;
            userData.refresh_token = response.refresh_token;
            updateUser(userData);
            add_to_cart();
          } else if (userData.login_type !== "guest") {
            const newUser = { ...initUser, uuid: userData.uuid };
            updateUser(newUser);
            router.push("/login?redirect=" + router.asPath);
          }
        } else {
          try {
            const body = {data: {...data, type: "add to cart", error: error?.response?.data || error, user_id: userData.user_id}, timestamp: new Date().getTime()};
            await axios.post(
              "/api/report",
              {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
            )
          } catch (error) {
            console.log(error);
          }

          if (userData.login_type !== "guest") {
            const newUser = { ...initUser, uuid: userData.uuid };
            updateUser(newUser);
            router.push("/login?redirect=" + router.asPath);
          } else {
            // how to handle this?
          }
        }
      }
    }
  }

  const update_to_cart = async () => {
    if (variant != null && variant !== undefined && (variant.qty > 0 || (props.virtual_product && props.selectedCustomVariant !== null && props.selectedCustomVariant.qty > 0))) {
      if (props.virtual_product) {
        if (props.toName === "") {
          setAlertMessage("Silahkan isi nama penerima");
          return;
        } else if (props.toEmail === "") {
          setAlertMessage("Silahkan isi email penerima!");
          return;
        } else if (!email_regex.test(props.toEmail)) {
          setAlertMessage("Email penerima yang anda masukkan salah!");
          return;
        } else if (props.toMobile === "") {
          setAlertMessage("Silahkan isi nomor telepon anda");
          return;
        } else if (props.selectedCustomVariant === null) {
          setAlertMessage("Silahkan pilih " + props.custom_variant_list[0].category + " produk");
          return;
        } else if (props.selectedCustomVariant.qty === 0) {
          setAlertMessage("Stok produk tidak tersedia");
          return;
        } else if (props.selectedCustomVariant.qty < qty) {
          setAlertMessage("Jumlah produk melebihi stok yang tersedia");
          return;
        } else if (props.selectedCustomVariant.pregnancy_status) {
          if (props.pregnancy === "") {
            setAlertMessage("Silahkan isi kondisi anda saat ini, apakah anda sedang hamil?");
            return;
          } else if (props.pregnancy === "pregnant" && props.duDate === null) {
            setAlertMessage("Silahkan isi waktu perkiraan anak anda lahir");
            return;
          }
        } else if (props.selectedCustomVariant.child_status) {
          if (props.childName === "") {
            setAlertMessage("Silahkan isi nama anak anda");
            return;
          } else if (props.childAge === null) {
            setAlertMessage("Silahkan isi usia anak anda");
            return;
          } else if (!/^\+?[1-9]\d*$/.test(props.childAge.toString()) || props.childAge < 0) {
            setAlertMessage("Silahkan isi usia anak anda dengan benar");
            return;
          } else if (props.childGender === "") {
            setAlertMessage("Silahkan pilih jenis kelamin anak anda");
            return;
          }
        }
      }

      const data = {
        variant_id: variant.variant_id,
        qty: qty,
        notify_email: props.toEmail,
        from_name: props.fromName,
        to_name: props.toName,
        phone: props.toMobile,
        custom_variant_id: props.selectedCustomVariant !== null ? props.selectedCustomVariant.id : null,
        pregnancy_status: props.selectedCustomVariant !== null && props.selectedCustomVariant.pregnancy_status ? props.pregnancy : null,
        due_date: props.selectedCustomVariant !== null && props.selectedCustomVariant.pregnancy_status ? props.duDate?.toISOString().slice(0, 7) : null,
        child_name: props.selectedCustomVariant !== null && props.selectedCustomVariant.child_status ? props.childName : null,
        child_age: props.selectedCustomVariant !== null && props.selectedCustomVariant.child_status ? props.childAge : null,
        child_gender: props.selectedCustomVariant !== null && props.selectedCustomVariant.child_status ? props.childGender : null,
      }

      try {
        const response = await axios.put(
          '/api/cart/' + props.id,
          data,
          { headers: { Authorization: 'Bearer ' + userData.token } }
        )

        if (response.data.status === "ok") {
          event("update_cart", {
            category: "ecommerce",
            value: variant.sales_price,
            currency: process.env.NEXT_PUBLIC_CURRENCY_CODE,
            items: [
              {
                item_id: props.product_code,
                item_name: props.name,
                item_variant: variant.product_code,
                brand: props.brand,
                price: variant.special_price !== 0 ? variant.special_price : props.special_price,
                quantity: qty,
                image: variant.cart_image
              }
            ]
          });

          try {
            window.fbq('track', 'UpdateCart', {
              content_ids: [props.product_code],
              content_name: props.name,
              content_type: 'product',
              value: (variant.special_price !== 0 ? variant.special_price : props.special_price !== 0 ? props.special_price : variant.sales_price) * qty,
              currency: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'IDR',
            })
          } catch (error) { }

          try {
            window.gtag('event', 'UpdateCart', {
              items: [
                {
                  id: props.product_code,
                  name: props.name,
                  variant: variant.product_code,
                  brand: props.brand,
                  quantity: qty,
                  price: variant.special_price !== 0 ? variant.special_price : props.special_price !== 0 ? props.special_price : variant.sales_price,
                }
              ]
            })
          } catch (error) { }

          const idx = cartsData.data.findIndex(cart => cart.id === props.id);

          if (idx > -1) {
            cartsData.data[idx].qty = qty;
            cartsData.data[idx].product_variant_id = variant.variant_id;
            cartsData.data[idx].image = variant.cart_image;
            cartsData.data[idx].special_price = variant.special_price;
            cartsData.data[idx].sales_price = variant.sales_price;
            cartsData.data[idx].customVariant = props.selectedCustomVariant;
            cartsData.data[idx].fromName = props.fromName;
            cartsData.data[idx].toName = props.toName;
            cartsData.data[idx].toEmail = props.toEmail;
            cartsData.data[idx].toMobile = props.toMobile;
            cartsData.data[idx].toMessage = props.toMessage;
            cartsData.data[idx].pregnancy = props.pregnancy;
            cartsData.data[idx].duDate = props.duDate;
            cartsData.data[idx].childName = props.childName;
            cartsData.data[idx].childAge = props.childAge;
            cartsData.data[idx].childGender = props.childGender;
            cartsData.data[idx].pwp_name = props.pwp_name;
            cartsData.data[idx].pwp = props.pwp;
            cartsData.data[idx].shared_sales_price = props.shared_sales_price;
            cartsData.data[idx].type = affiliate !== null && affiliate !== undefined ? "affiliate" : "ec";
            cartsData.update_date = Date.now();
            updateCarts(cartsData);
            setCartsData(cartsData);
          }
        }
      } catch (error: any) {
        if (error.response.data.error === "Invalid credentials" && userData.refresh_token !== "") {
          const response: any = await getNewToken(userData.token, userData.refresh_token, userData.uuid, userData.user_id);

          if (response !== "error") {
            userData.token = response.token;
            userData.refresh_token = response.refresh_token;
            updateUser(userData);
            update_to_cart();
          } else if (userData.login_type !== "guest") {
            const newUser = { ...initUser, uuid: userData.uuid };
            updateUser(newUser);
            router.push("/login?redirect=" + router.asPath);
          }
        } else {
          try {
            const body = {data: {...data, type: "update cart", error: error?.response?.data || error, user_id: userData.user_id}, timestamp: new Date().getTime()};
            await axios.post(
              "/api/report",
              {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
            )
          } catch (error) {
            console.log(error);
          }

          if (userData.login_type !== "guest") {
            const newUser = { ...initUser, uuid: userData.uuid };
            updateUser(newUser);
            router.push("/login?redirect=" + router.asPath);
          } else {
            // how to handle this?
          }
        }
      } finally {
        setOpen(false);
      }
    }
  }

  const show_sales_price = () => {
    if (affiliate !== null && affiliate !== undefined && props.shared_sales_price !== null && props.shared_sales_price !== undefined && props.shared_sales_price > 0) {
      return (
        <p className={`flex text-sm text-affiliatePrice`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{props.shared_sales_price.toLocaleString()}
        </p>
      )
    } else {
      const sales_price = get_sales_price(props, variant);

      if (Array.isArray(sales_price.price)) {
        return (
          <p className={`flex text-sm text-price`}>
            {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[0].toLocaleString()} ~ {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[1].toLocaleString()}
          </p>
        )
      } else {
        return (
          <p className={`flex text-sm ${sales_price.promotion ? "text-mooimom" : "text-price"}`}>
            {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price.toLocaleString()}
          </p>
        )
      }
    }
  }

  const show_origin_price = () => {
    const origin_price = get_original_price(props, variant);

    if (origin_price === null) {
      return (<></>)
    } else {
      return (
        <p className="text-xs line-through text-originPrice">
          {process.env.NEXT_PUBLIC_CURRENCY}{origin_price.toLocaleString()}
        </p>
      )
    }
  }

  useEffect(() => {
    if (open) {
      setAffiliate(Cookies.get(window.btoa("affiliate")));
    }
  }, [open])

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  useEffect(() => {
    setCartsData(getCarts());
  }, [getCarts, carts])

  useEffect(() => {
    if (variant !== null && variant !== undefined) {
      setDisplayImage(variant.cart_image);
    }
  }, [variant])

  useEffect(() => {
    setVariant(props.variant);
  }, [props.variant])

  useEffect(() => {
    if (alertMessage !== "") {
      setAlertOpen(true);
    }
  }, [alertMessage])

  useEffect(() => {
    if (!alertOpen) {
      setAlertMessage("");
    }
  }, [alertOpen])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 z-[60]" />
        <Dialog.Content
          className="fixed bottom-0 md:bottom-auto md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 rounded-t-lg md:rounded-lg w-full md:w-fit md:max-w-1/2 md:min-w-[400px] mx-auto z-[65] h-fit max-h-[70%] md:max-h-1/2 outline-none focus:ring-transparent"
          onOpenAutoFocus={(event) => event.preventDefault()}
        >
          <Dialog.Title />
          <Dialog.Description />
          <div className="p-8 bg-white rounded-t-lg md:hidden">
            <div className="flex items-start space-x-4">
              <Image
                src={displayImage}
                width={800}
                height={800}
                alt={name + " gallery"}
                quality={100}
                className="w-[90px] aspect-square object-contain"
              />
              <div className="">
                <div className="text-xs font-normal text-sku font-poppins">{product_code}</div>
                <div className="text-xs font-semibold leading-6 text-attributeTitle font-poppins">{name}</div>
                <div className="flex items-center space-x-2 font-bold font-poppins">
                  {show_sales_price()}
                  {show_origin_price()}
                </div>
              </div>
            </div>
            <div className="overflow-y-auto add-to-cart-attribute-height">
              <Attribute
                product_code={props.product_code}
                attribute_value_list={props.attribute_value_list}
                product_variant_list={props.product_variant_list}
                attributes={attributesData.data}
                style="popup"
                variant={variant}
                setVariant={setVariant}
                virtual_product={props.virtual_product}
                need_shipping={props.need_shipping}
                custom_variant_list={props.custom_variant_list}
                selectedCustomVariant={props.selectedCustomVariant}
                setSelectedCustomVariant={props.setSelectedCustomVariant}
                fromName={props.fromName}
                setFromName={props.setFromName}
                toName={props.toName}
                setToName={props.setToName}
                toEmail={props.toEmail}
                setToEmail={props.setToEmail}
                toMobile={props.toMobile}
                setToMobile={props.setToMobile}
                toMessage={props.toMessage}
                setToMessage={props.setToMessage}
                pregnancy={props.pregnancy}
                setPregnancy={props.setPregnancy}
                duDate={props.duDate}
                setDuDate={props.setDuDate}
                childName={props.childName}
                setChildName={props.setChildName}
                childAge={props.childAge}
                setChildAge={props.setChildAge}
                childGender={props.childGender}
                setChildGender={props.setChildGender}
                use_product_option={props.use_product_option ? props.use_product_option : false}
              />
              {variant !== null && variant !== undefined && variant.qty === 0 && (
                <div className="my-4 text-sm italic leading-9 text-promotionPrice font-poppins">Stok produk tidak tersedia</div>
              )}
              <div className="mt-6 text-sm font-normal text-black capitalize md:text-xs font-poppins">Jumlah</div>
              <div className="mt-2 border border-sku border-solid py-2 px-4 flex justify-between items-center w-[130px] relative rounded-lg">
                {variant !== null && variant !== undefined && variant.qty === 0 && (
                  <div className="absolute top-0 left-0 z-10 w-full h-full bg-gray-300 opacity-50"></div>
                )}
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/minus.png"}
                  alt="minus icon"
                  width={12}
                  height={12}
                  quality={100}
                  className="cursor-pointer"
                  onClick={minus_qty}
                />
                <p className="w-[60px] text-center text-black text-base bg-white">{qty}</p>
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/plus.png"}
                  alt="plus icon"
                  width={12}
                  height={12}
                  quality={100}
                  className="cursor-pointer"
                  onClick={plus_qty}
                />
              </div>
            </div>
            {action === "add" ? (
              <div
                className={`w-full p-2 ${variant === null || variant === undefined || (variant !== null && variant !== undefined && variant.qty === 0 || props.selectedCustomVariant !== null && props.selectedCustomVariant !== undefined && props.selectedCustomVariant.qty === 0) ? "bg-originPrice" : "bg-review cursor-pointer"} text-center text-white text-base font-poppins font-normal mt-6 rounded-lg`}
                onClick={() => variant === null || variant === undefined || (variant !== null && variant !== undefined && variant.qty === 0 || props.selectedCustomVariant !== null && props.selectedCustomVariant !== undefined && props.selectedCustomVariant.qty === 0) ? "" : add_to_cart()}
              >
                Masukkan Keranjang
              </div>
            ) : (
              <div
                className={`w-full p-2 ${variant === null || variant === undefined || (variant !== null && variant !== undefined && variant.qty === 0 || props.selectedCustomVariant !== null && props.selectedCustomVariant !== undefined && props.selectedCustomVariant.qty === 0) ? "bg-originPrice" : "bg-review cursor-pointer"} text-center text-white text-base font-poppins font-normal mt-6 rounded-lg`}
                onClick={() => variant === null || variant === undefined || (variant !== null && variant !== undefined && variant.qty === 0 || props.selectedCustomVariant !== null && props.selectedCustomVariant !== undefined && props.selectedCustomVariant.qty === 0) ? "" : update_to_cart()}
              >
                Simpan
              </div>
            )}
          </div>
          <div className="hidden w-full p-8 bg-white rounded-lg md:block h-fit">
            <div className="flex justify-end mb-4">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/close.png"}
                alt="close icon"
                width={12}
                height={12}
                quality={100}
                onClick={() => setOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <div className="grid grid-cols-3 gap-8 h-fit">
              <div className="col-span-1">
                <Image
                  src={displayImage}
                  width={800}
                  height={800}
                  alt={name + " gallery"}
                  quality={100}
                  className="object-contain w-full aspect-square"
                />
              </div>
              <div className="col-span-2">
                <div className="text-xs font-normal text-sku font-poppins">{product_code}</div>
                <div className="mt-1 text-xs font-bold text-black font-poppins">{name}</div>
                <div className="flex items-center mt-1 space-x-2 font-bold font-poppins">
                  {show_sales_price()}
                  {show_origin_price()}
                </div>
                <hr className="mt-4 bg-reviewBg" />
                <div className="h-[200px] overflow-y-auto">
                  <Attribute
                    product_code={props.product_code}
                    attribute_value_list={props.attribute_value_list}
                    product_variant_list={props.product_variant_list}
                    attributes={attributesData.data}
                    style="popup"
                    variant={variant}
                    setVariant={setVariant}
                    virtual_product={props.virtual_product}
                    need_shipping={props.need_shipping}
                    custom_variant_list={props.custom_variant_list}
                    selectedCustomVariant={props.selectedCustomVariant}
                    setSelectedCustomVariant={props.setSelectedCustomVariant}
                    fromName={props.fromName}
                    setFromName={props.setFromName}
                    toName={props.toName}
                    setToName={props.setToName}
                    toEmail={props.toEmail}
                    setToEmail={props.setToEmail}
                    toMobile={props.toMobile}
                    setToMobile={props.setToMobile}
                    toMessage={props.toMessage}
                    setToMessage={props.setToMessage}
                    pregnancy={props.pregnancy}
                    setPregnancy={props.setPregnancy}
                    duDate={props.duDate}
                    setDuDate={props.setDuDate}
                    childName={props.childName}
                    setChildName={props.setChildName}
                    childAge={props.childAge}
                    setChildAge={props.setChildAge}
                    childGender={props.childGender}
                    setChildGender={props.setChildGender}
                    use_product_option={props.use_product_option ? props.use_product_option : false}
                  />
                  {variant !== null && variant !== undefined && variant.qty === 0 && (
                    <div className="my-4 text-sm italic leading-9 text-promotionPrice font-poppins">Stok produk tidak tersedia</div>
                  )}
                </div>
              </div>
            </div>
            <hr className="w-full my-6 bg-reviewBg" />
            <div className="flex items-center justify-end space-x-4">
              <div className="border rounded-lg border-sku border-solid py-2 px-4 flex justify-between items-center w-[120px] relative">
                {variant !== null && variant !== undefined && variant.qty === 0 && (
                  <div className="absolute top-0 left-0 z-10 w-full h-full bg-gray-300 opacity-50"></div>
                )}
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/minus.png"}
                  alt="minus icon"
                  width={10}
                  height={10}
                  quality={100}
                  className="cursor-pointer"
                  onClick={minus_qty}
                />
                <p className="w-[60px] text-center text-black text-sm bg-white">{qty}</p>
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/plus.png"}
                  alt="plus icon"
                  width={10}
                  height={10}
                  quality={100}
                  className="cursor-pointer"
                  onClick={plus_qty}
                />
              </div>
              {action === "add" ? (
                <div
                  className={`w-[185px] p-2 ${variant === null || variant === undefined || (variant !== null && variant !== undefined && variant.qty === 0 || props.selectedCustomVariant !== null && props.selectedCustomVariant !== undefined && props.selectedCustomVariant.qty === 0) ? "bg-originPrice" : "bg-review cursor-pointer"} text-center text-white text-sm font-poppins font-normal rounded-lg`}
                  onClick={() => variant === null || variant === undefined || (variant !== null && variant !== undefined && variant.qty === 0 || props.selectedCustomVariant !== null && props.selectedCustomVariant !== undefined && props.selectedCustomVariant.qty === 0) ? "" : add_to_cart()}
                >
                  Masukkan Keranjang
                </div>
              ) : (
                <div
                  className={`w-[185px] p-2 ${variant === null || variant === undefined || (variant !== null && variant !== undefined && variant.qty === 0|| props.selectedCustomVariant !== null && props.selectedCustomVariant !== undefined && props.selectedCustomVariant.qty === 0) ? "bg-originPrice" : "bg-review cursor-pointer"} text-center text-white text-sm font-poppins font-normal rounded-lg`}
                  onClick={() => variant === null || variant === undefined || (variant !== null && variant !== undefined && variant.qty === 0 || props.selectedCustomVariant !== null && props.selectedCustomVariant !== undefined && props.selectedCustomVariant.qty === 0) ? "" : update_to_cart()}
                >
                  Simpan
                </div>
              )}
            </div>
          </div>
          <AlertDialog open={alertOpen} setOpen={setAlertOpen} message={alertMessage} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default AddToCart
