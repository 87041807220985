import { useEffect, useState } from "react";
import Image from 'next/image';
import { AttributeValueItem } from "@/lib/constants";
import { ProductAttributeItemProps } from "./constants";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


const Attribute = (props: ProductAttributeItemProps) => {
  const { attribute_value_list, product_variant_list, attributes, variant, setVariant } = props;
  const [currentCode, setCurrentCode] = useState<string>("")
  const [colors, setColors] = useState<Array<AttributeValueItem>>([])
  const [sizes, setSizes] = useState<Array<AttributeValueItem>>([])
  const [stages, setStages] = useState<Array<AttributeValueItem>>([])
  const [patterns, setPatterns] = useState<Array<AttributeValueItem>>([])
  const [genders, setGenders] = useState<Array<AttributeValueItem>>([])
  const [flavours, setFlavours] = useState<Array<AttributeValueItem>>([])
  const [options, setOptions] = useState<Array<AttributeValueItem>>([])
  const [colorTitle, setColorTitle] = useState<string>("")
  const [sizeTitle, setSizeTitle] = useState<string>("")
  const [stageTitle, setStageTitle] = useState<string>("")
  const [patternTitle, setPatternTitle] = useState<string>("")
  const [genderTitle, setGenderTitle] = useState<string>("")
  const [flavourTitle, setFlavourTitle] = useState<string>("")
  const [optionTitle, setOptionTitle] = useState<string>("")
  const [selectColor, setSelectColor] = useState<number>(0)
  const [selectSize, setSelectSize] = useState<number>(0)
  const [selectStage, setSelectStage] = useState<number>(0)
  const [selectPattern, setSelectPattern] = useState<number>(0)
  const [selectGender, setSelectGender] = useState<number>(0)
  const [selectFlavour, setSelectFlavour] = useState<number>(0)
  const [selectOption, setSelectOption] = useState<number>(0)
  const [disabledColor, setDisabledColor] = useState<boolean>(false)
  const [disabledSize, setDisabledSize] = useState<boolean>(false)
  const [disabledStage, setDisabledStage] = useState<boolean>(false)
  const [disabledPattern, setDisabledPattern] = useState<boolean>(false)
  const [disabledGender, setDisabledGender] = useState<boolean>(false)
  const [disabledFlavour, setDisabledFlavour] = useState<boolean>(false)
  const [disabledOption, setDisabledOption] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [init, setInit] = useState<boolean>(false);

  // fetch selected variant
  useEffect(() => {
    const attr = [selectColor, selectSize, selectStage, selectPattern, selectGender, selectFlavour, selectOption].filter((value) => value !== 0).sort((a, b) => a - b)

    if (attr.length === 0 && variant !== null) {
      attr.push(...variant.attribute_value_list);
    }

    const variants = product_variant_list.filter((variant) => variant.attribute_value_list.sort((a, b) => a - b).toString() === attr.toString())

    if (variants.length > 0) {
      setVariant(variants[0]);
    } else if (props.use_product_option) {
      const singleAttrVariants = product_variant_list.filter(variant => variant.attribute_value_list.length === 1);

      if (singleAttrVariants.length > 0) {
        let isSingle = false;
        singleAttrVariants.forEach(singleVariant => {
          const singleAttr = singleVariant.attribute_value_list[0];
          if (attr.includes(singleAttr)) {
            const filtered = attr.filter(value => value !== singleAttr);
            filtered.forEach(attr => {
              switch (attr) {
                case selectColor:
                  setSelectColor(0);
                  setDisabledColor(true);
                  break;
                case selectSize:
                  setSelectSize(0);
                  setDisabledSize(true);
                  break;
                case selectStage:
                  setSelectStage(0);
                  setDisabledStage(true);
                  break;
                case selectPattern:
                  setSelectPattern(0);
                  setDisabledPattern(true);
                  break;
                case selectGender:
                  setSelectGender(0);
                  setDisabledGender(true);
                  break;
                case selectFlavour:
                  setSelectFlavour(0);
                  setDisabledFlavour(true);
                  break;
                case selectOption:
                  setSelectOption(0);
                  setDisabledOption(true);
                  break;
              }
            });

            setVariant(singleVariant);
            isSingle = true;
          }
        });

        if (!isSingle) {
          setVariant(null);
        }
      }
    }
  }, [selectColor, selectSize, selectStage, selectPattern, selectGender, selectFlavour, selectOption, product_variant_list, setVariant])

  useEffect(() => {
    const parser_attributes = async () => {
      try {
        for (var i = 0; i < attributes.length; i++) {
          const filtered = attributes[i].attribute_values.filter((attribute) => attribute_value_list.some((value) => attribute.id === value)).sort((a, b) => (a.order === b.order) ? a.id - b.id : a.order - b.order);

          let inVariant: any = null;

          if (variant !== null && variant !== undefined) {
            inVariant = filtered.find((attr) => variant.attribute_value_list.find((value) => value === attr.id));
          }

          switch (attributes[i].slug) {
            case "color":
              setColorTitle(attributes[i].name);
              setColors(filtered);

              if (inVariant) {
                setSelectColor(inVariant.id);
              }
              break;

            case "size":
              setSizeTitle(attributes[i].name);
              setSizes(filtered);

              if (inVariant) {
                setSelectSize(inVariant.id);
              }
              break;

            case "stage":
              setStageTitle(attributes[i].name);
              setStages(filtered);

              if (inVariant) {
                setSelectStage(inVariant.id);
              }
              break;

            case "pattern":
              setPatternTitle(attributes[i].name);
              setPatterns(filtered);

              if (inVariant) {
                setSelectPattern(inVariant.id);
              }
              break;

            case "gender":
              setGenderTitle(attributes[i].name);
              setGenders(filtered);

              if (inVariant) {
                setSelectGender(inVariant.id);
              }
              break;

            case "flavour":
              setFlavourTitle(attributes[i].name);
              setFlavours(filtered);

              if (inVariant) {
                setSelectFlavour(inVariant.id);
              }
              break;

            case "option":
              setOptionTitle(attributes[i].name);
              setOptions(filtered);

              if (inVariant) {
                setSelectOption(inVariant.id);
              }
              break;
          }
        }
      } finally {
        setIsLoading(false)
      }
    }

    if (init) {
      parser_attributes();
      setInit(false);
    }
  }, [attribute_value_list, attributes, init, variant]);

  useEffect(() => {
    if (init) {
      setIsLoading(false);
    }
  }, [init]);

  useEffect(() => {
    if (currentCode !== props.product_code || variant === null) {
      setCurrentCode(props.product_code);
      setInit(true);
      setDisabledColor(false);
      setDisabledSize(false);
      setDisabledStage(false);
      setDisabledPattern(false);
      setDisabledGender(false);
      setDisabledFlavour(false);
      setDisabledOption(false);
    }
  }, [currentCode, props.product_code, variant]);

  const handleClick = (type: string, value: number) => {
    if (props.setClickAttribute) {
      props.setClickAttribute(true);
    }

    switch (type) {
      case "color":
        if (!disabledColor) {
          setSelectColor(value);

          if (props.setSelectProductColor) {
            props.setSelectProductColor(value);
          }
        }
        break;

      case "size":
        if (!disabledSize) {
          setSelectSize(value);
        }
        break;

      case "stage":
        if (!disabledStage) {
          setSelectStage(value);
        }
        break;

      case "pattern":
        if (!disabledPattern) {
          setSelectPattern(value);
        }
        break;

      case "gender":
        if (!disabledGender) {
          setSelectGender(value);
        }
        break;

      case "flavor":
        if (!disabledFlavour) {
          setSelectFlavour(value);
        }
        break;

      case "option":
        if (!disabledOption) {
          setSelectOption(value);
        }
        break;
    }
  }

  useEffect(() => {
    if (props.pregnancy !== "pregnant") {
      props.setDuDate(null);
    }
  }, [props, props.pregnancy]);

  const renderVirtualProduct = () => {
    if (props.custom_variant_list && props.custom_variant_list.length > 0) {
      return (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-6 md:mt-4"}`}>
          <div className="text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins">{props.custom_variant_list[0].category}</div>
          <div className="flex flex-wrap">
            {props.custom_variant_list.map((variant, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 py-2 px-4 border min-w-[42px] rounded-lg md:rounded text-center border-solid ${props.selectedCustomVariant !== null && variant.id === props.selectedCustomVariant.id ? "border-mooimom border-[2px] border-solid" : "border-attributeBorder"} text-attributeTitle text-xs font-poppins font-medium cursor-pointer`}
                onClick={() => props.setSelectedCustomVariant(variant)}
              >
                {variant.name}
              </div>
            ))}
          </div>
          {props.selectedCustomVariant !== null && props.selectedCustomVariant.description.length > 0 && (
            <p className={`px-4 py-2 text-xs text-black border w-full border-solid rounded-lg border-attributeBorder bg-reviewBg ${props.style === "normal" ? "mt-4" : "mt-2 md:mt-4"}`} dangerouslySetInnerHTML={{__html: props.selectedCustomVariant.description.replace(/(?:\r\n|\r|\n)/g, "<br />")}}></p>
          )}
          {props.style !== "normal" && (
            <>
              <p className="mt-6 text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins md:mt-4">Nama</p>
              <input
                type="text"
                className="w-full px-4 py-2 mt-2 text-xs text-black border border-solid rounded-lg outline-none border-attributeBorder focus:ring-transparent focus:border-attributeBorder md:mt-1"
                placeholder="Silahkan isi nama"
                value={props.toName}
                onChange={(e) => props.setToName(e.target.value)}
              />
              <p className="mt-6 text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins md:mt-4">Nomor Telepon</p>
              <input
                type="text"
                className="w-full px-4 py-2 mt-2 text-xs text-black border border-solid rounded-lg outline-none border-attributeBorder focus:ring-transparent focus:border-attributeBorder md:mt-1"
                placeholder="Silahkan isi nomor telepon anda"
                value={props.toMobile}
                onChange={(e) => props.setToMobile(e.target.value)}
              />
              <p className="mt-6 text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins md:mt-4">Email</p>
              <input
                type="email"
                className="w-full px-4 py-2 mt-2 text-xs text-black border border-solid rounded-lg outline-none border-attributeBorder focus:ring-transparent focus:border-attributeBorder md:mt-1"
                placeholder="Silahkan isi email"
                value={props.toEmail}
                onChange={(e) => props.setToEmail(e.target.value)}
              />
              {props.selectedCustomVariant !== null && (
                <>
                  {props.selectedCustomVariant.pregnancy_status && (
                    <>
                      <p className="mt-6 text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins md:mt-4">Kondisi Kehamilan:</p>
                      <div className="flex my-2">
                        <div
                          className="flex items-center mr-4 cursor-pointer"
                          onClick={() => props.setPregnancy("pregnant")}
                        >
                          <input
                            type="radio"
                            name="pregnancy"
                            value="pregnant"
                            className={`h-4 w-4 rounded-sm border-mooimom text-mooimom cursor-pointer focus:ring-transparent ${props.pregnancy === "pregnant" ? 'bg-mooimom border-transparent' : 'bg-white'}`}
                            checked={props.pregnancy === "pregnant"}
                            onChange={() => props.setPregnancy("pregnant")}
                          />
                          <p className="ml-2 text-xs text-black font-poppins">Hamil</p>
                        </div>
                        <div
                          className="flex items-center mr-4 cursor-pointer"
                          onClick={() => props.setPregnancy("notpregnant")}
                        >
                          <input
                            type="radio"
                            name="pregnancy"
                            value="notpregnant"
                            className={`h-4 w-4 rounded-sm border-mooimom text-mooimom cursor-pointer focus:ring-transparent ${props.pregnancy === "notpregnant" ? 'bg-mooimom border-transparent' : 'bg-white'}`}
                            checked={props.pregnancy === "notpregnant"}
                            onChange={() => props.setPregnancy("notpregnant")}
                          />
                          <p className="ml-2 text-xs text-black font-poppins">Tidak Hamil</p>
                        </div>
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={() => props.setPregnancy("pregnantnotsure")}
                        >
                          <input
                            type="radio"
                            name="pregnancy"
                            value="pregnantnotsure"
                            className={`h-4 w-4 rounded-sm border-mooimom text-mooimom cursor-pointer focus:ring-transparent ${props.pregnancy === "pregnantnotsure" ? 'bg-mooimom border-transparent' : 'bg-white'}`}
                            checked={props.pregnancy === "pregnantnotsure"}
                            onChange={() => props.setPregnancy("pregnantnotsure")}
                          />
                          <p className="ml-2 text-xs text-black font-poppins">Tidak Yakin</p>
                        </div>
                      </div>
                      {props.pregnancy === "pregnant" && (
                        <>
                          <p className="mt-6 text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins md:mt-4">Pada tahun dan bulan berapa anak anda akan lahir?</p>
                          <DatePicker
                            selected={props.duDate}
                            onChange={(date: Date) => props.setDuDate(date)}
                            dateFormat="yyyy-MM"
                            className="w-full px-4 py-2 mt-2 text-xs text-black border border-solid rounded-lg outline-none border-attributeBorder focus:ring-transparent focus:border-attributeBorder md:mt-1"
                          />
                        </>
                      )}
                    </>
                  )}
                  {props.selectedCustomVariant.child_status && (
                    <>
                      <p className="mt-6 text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins md:mt-4">Nama Anak</p>
                      <input
                        type="text"
                        className="w-full px-4 py-2 mt-2 text-xs text-black border border-solid rounded-lg outline-none border-attributeBorder focus:ring-transparent focus:border-attributeBorder md:mt-1"
                        placeholder="Nama Anak"
                        value={props.childName}
                        onChange={(e) => props.setChildName(e.target.value)}
                      />
                      <p className="mt-6 text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins md:mt-4">Umur Anak</p>
                      <input
                        type="text"
                        className="w-full px-4 py-2 mt-2 text-xs text-black border border-solid rounded-lg outline-none border-attributeBorder focus:ring-transparent focus:border-attributeBorder md:mt-1"
                        placeholder="Umur Anak (Mohon isi hanya dengan angka)"
                        value={props.childAge ? props.childAge : "" }
                        onChange={(e) => props.setChildAge(parseInt(e.target.value))}
                      />
                      <p className="mt-6 text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins md:mt-4">Jenis Kelamin Anak</p>
                      <div className="flex my-2">
                        <div
                          className="flex items-center mr-4 cursor-pointer"
                          onClick={() => props.setChildGender("boy")}
                        >
                          <input
                            type="radio"
                            name="childGender"
                            value="boy"
                            className={`h-4 w-4 rounded-sm border-mooimom text-mooimom cursor-pointer focus:ring-transparent ${props.childGender === "boy" ? 'bg-mooimom border-transparent' : 'bg-white'}`}
                            checked={props.childGender === "boy"}
                            onChange={() => props.setChildGender("boy")}
                          />
                          <p className="ml-2 text-xs text-black font-poppins">Laki-laki</p>
                        </div>
                        <div
                          className="flex items-center mr-4 cursor-pointer"
                          onClick={() => props.setChildGender("girl")}
                        >
                          <input
                            type="radio"
                            name="childGender"
                            value="girl"
                            className={`h-4 w-4 rounded-sm border-mooimom text-mooimom cursor-pointer focus:ring-transparent ${props.childGender === "girl" ? 'bg-mooimom border-transparent' : 'bg-white'}`}
                            checked={props.childGender === "girl"}
                            onChange={() => props.setChildGender("girl")}
                          />
                          <p className="ml-2 text-xs text-black font-poppins">Perempuan</p>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )
    } else {
      return (
        <></>
      )
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {colors.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-6 md:mt-4"}`}>
          <div className="text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins">{colorTitle}</div>
          <div className="flex flex-wrap">
            {colors.map((color, index) => (
              <Image
                key={index}
                src={color.image}
                alt={color.name}
                width={props.style === "normal" ? 40 : 28}
                height={props.style === "normal" ? 40 : 28}
                quality={100}
                className={`mt-2 mr-4 cursor-pointer ${props.style === "normal" ? "rounded-none": "rounded-full"} md:rounded-none ${selectColor === color.id ? "border-mooimom border-[2px] border-solid" : "border-attributeBorder"} ${disabledColor ? "bg-originPrice border-originPrice" : ""}`}
                onClick={() => handleClick("color", color.id)}
              />
            ))}
          </div>
        </div>
      )}
      {sizes.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-6 md:mt-4"}`}>
          <div className="text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins">{sizeTitle}</div>
          <div className="flex flex-wrap">
            {sizes.map((size, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 py-2 px-4 border min-w-[42px] rounded-lg md:rounded text-center border-solid ${selectSize === size.id ? "border-mooimom border-[2px] border-solid" : "border-attributeBorder"} ${disabledSize ? "bg-originPrice border-originPrice" : ""} text-attributeTitle text-xs font-poppins font-medium cursor-pointer`}
                onClick={() => {if (!disabledSize) {handleClick("size", size.id)}}}
              >
                {size.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {stages.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-6 md:mt-4"}`}>
          <div className="text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins">{stageTitle}</div>
          <div className="flex flex-wrap">
            {stages.map((stage, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 py-2 px-4 border min-w-[42px] rounded-lg md:rounded text-center border-solid ${selectStage === stage.id ? "border-mooimom border-[2px] border-solid" : "border-attributeBorder"} ${disabledStage ? "bg-originPrice border-originPrice" : ""} text-attributeTitle text-xs font-poppins font-medium cursor-pointer`}
                onClick={() => handleClick("stage", stage.id)}
              >
                {stage.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {patterns.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-6 md:mt-4"}`}>
          <div className="text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins">{patternTitle}</div>
          <div className="flex flex-wrap">
            {patterns.map((pattern, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 py-2 px-4 border min-w-[42px] rounded-lg md:rounded text-center border-solid ${selectPattern === pattern.id ? "border-mooimom border-[2px] border-solid" : "border-attributeBorder"} ${disabledPattern ? "bg-originPrice border-originPrice" : ""} text-attributeTitle text-xs font-poppins font-medium cursor-pointer`}
                onClick={() => handleClick("pattern", pattern.id)}
              >
                {pattern.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {genders.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-6 md:mt-4"}`}>
          <div className="text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins">{genderTitle}</div>
          <div className="flex flex-wrap">
            {genders.map((gender, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 py-2 px-4 border min-w-[42px] rounded-lg md:rounded text-center border-solid ${selectGender === gender.id ? "border-mooimom border-[2px] border-solid" : "border-attributeBorder"} ${disabledGender ? "bg-originPrice border-originPrice" : ""} text-attributeTitle text-xs font-poppins font-medium cursor-pointer`}
                onClick={() => handleClick("gender", gender.id)}
              >
                {gender.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {flavours.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-6 md:mt-4"}`}>
          <div className="text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins">{flavourTitle}</div>
          <div className="flex flex-wrap">
            {flavours.map((flavour, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 py-2 px-4 border min-w-[42px] rounded-lg md:rounded text-center border-solid ${selectFlavour === flavour.id ? "border-mooimom border-[2px] border-solid" : "border-attributeBorder"} ${disabledFlavour ? "bg-originPrice border-originPrice" : ""} text-attributeTitle text-xs font-poppins font-medium cursor-pointer`}
                onClick={() => handleClick("flavour", flavour.id)}
              >
                {flavour.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {options.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-6 md:mt-4"}`}>
          <div className="text-sm font-semibold capitalize md:text-xs text-attributeTitle font-poppins">{optionTitle}</div>
          <div className="flex flex-wrap">
            {options.map((option, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 py-2 px-4 border min-w-[42px] rounded-lg md:rounded text-center border-solid ${selectOption === option.id ? "border-mooimom border-[2px] border-solid" : "border-attributeBorder"} ${disabledOption ? "bg-originPrice border-originPrice" : ""} text-attributeTitle text-xs font-poppins font-medium cursor-pointer`}
                onClick={() => handleClick("option", option.id)}
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {props.virtual_product && (
        <>
          {renderVirtualProduct()}
        </>
      )}
    </>
  )
}

export default Attribute
